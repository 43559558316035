import React, {useState} from "react";
import {navigate} from "gatsby";
import LocationPicker from "./LocationPicker";
import ListingsAtLocation from "./ListingsAtLocation";

/**
  - wait for location, then wait for listings based on location
    - 
  - if cancelled, navigate back to /listings
 */

export default () => {
  const [location, _setLocation] = useState<GeolocationPosition>(undefined);

  if (location) {
    return <ListingsAtLocation location={location} />
  } else {
    return <LocationPicker onCancel={() => navigate("/listings")} onLocationPicked={location => {
      navigate(`/listings-at-location?lat=${location.coords.latitude}&lon=${location.coords.longitude}`);
      // setLocation(location);
    }}/>
  }
}

